import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                translation: {
                    components: {
                        status: 'Status',
                        '/': 'Orders',
                        '/withdraw': 'Balance',
                        '/settings': 'Settings',
                        requisites: 'Requisites',
                        available: 'available',
                        freeze: 'freeze',
                        send: 'Send',
                        rate: 'Rate',
                        get: 'Get',
                        date: 'Date',
                        id: 'ID',
                        confirm: 'Confirm',
                        access: 'Accesses',
                        enable: 'Status',
                        delete: 'Delete',
                        noRequired: 'No required',
                        dateCreate: 'Date create',
                        dateComplete: 'Date complite',
                        createSubuser: 'Create user',
                        actions: 'Actions',
                        total: 'Total',
                        successful: 'Success',
                        subUsers: 'User',
                        accepted: 'Accepted',
                        appeal: 'Appeal',
                        appealSum: 'Appeal amount',
                        dropFile: 'Drop file',
                        dragNDropeFile: 'Drag and drop file',
                        uploadFile: 'Upload file',
                        callback: 'Callback',
                        canceled: 'Canceled',
                        errorStatus: 'Canceled',
                        all: 'All',
                        ALL: 'All',
                        All: 'All',
                        error: 'Error',
                        noData: 'No data',
                        loading: 'Loading',
                        orderId: 'Order ID',
                        transactionId: 'Transaction ID',
                        fiatAmount: 'Fiat amount',
                        receipt: 'Receipt',
                        appealMessage: 'Enter your message',
                        inspect: 'View',
                        chooseAppealType: 'Choose appeal type',
                        recalc: 'Recalculation',
                        delay: 'Delay',
                        timezone: 'Timezone',
                        sendAppeal: 'Send',
                        changePassword: 'Change password',
                        sendOTP: 'Send 2FA code',
                        update: 'Update',
                        balance: 'Balance',
                        income: 'Income',
                        outcome: 'Outcome',
                        today: 'today',
                        buy: 'buy',
                        sell: 'sell',
                        logout: 'Log out',
                        updateSubuser: 'Update user',
                        main: 'Orders',
                        settings: 'Settings',
                        mainPage: '\n main page',
                        withdraw: 'Withdraw',
                        invoice: 'Invoice',
                        toWithdraw: 'Withdraw',
                        toInvoice: 'Invoice',
                        currency: 'Currency',
                        chooseBank: 'Choose bank',
                        balanceOperations: 'Balance operations',
                        ok: 'Окay',
                        balance_access: 'View balance',
                        statistics_access: 'View balance statistics',
                        transactions_access: 'View balance history',
                        tx_operations_access: 'Transaction Operations',
                        orders_access: 'View orders history',
                        invoice_access: 'Perform top-up operation',
                        withdrawal_access: 'Perform withdrawal operation',
                        password_access: 'Ability to change password',
                        api_key_access: 'Ability to create API keys',
                        conversion_access: 'View conversion history',
                        type: 'Type',
                        leaveEmpty: 'Fill if you need to change',
                        created: 'Created',
                        twofa: '2fa code',
                        cardNumber: 'Card number',
                        toMain: 'Back to orders',
                        toFiat: 'Transfer fiat to the card',
                        toWallet: ' to the address',
                        transfer: 'Transfer ',
                        toRequisites: ' to the requisites',
                        address: 'Address',
                        amount: 'Amount',
                        noType: 'No type',
                        in: 'In',
                        out: 'Out',
                        newPass: 'New password',
                        oldPass: 'Old password',
                        repeatedPass: 'Repeat password',
                        APIkeys: 'API keys',
                        changeAPIkeys: 'Change API keys',
                        successChangeKeys: 'Keys changed successfully',
                        except: 'Except replenishment for the specified requisites',
                        click: 'click to show key',
                        signUp: 'Sign Up',
                        signIn: 'Sign In',
                        login: 'Login',
                        password: 'Password',
                        save2fa: 'Save 2fa code in Google Authenticator',
                        send2Fa: 'Pass 2FA to the user to log into their personal account',
                        invite: 'Invite code',
                        noAccess: 'You do not have access to this page, contact the administrator',
                        successSignUp: 'Registration completed successfully',
                        successCopy: 'Copied',
                        errorCopy: 'Error',
                        success: 'Success',
                        loggedIn: 'You are logged in',
                        notLoggedIn: 'You are not authorized',
                        successSignIn: 'Successful authorization',
                        successAppeal: 'Application for appeal successfully created!',
                        errorAppeal: 'Error creating an appeal',
                        successCallback: 'Callback successfully sent',
                        errorCallback: 'Error sending callback',
                        loadingWithdraw: 'The withdrawal request has been sent',
                        successUpdatePass: 'Password updated successfully',
                        chooseToken: 'Choose token',
                        USDT: 'USDT',
                        RUB: 'RUB',
                        USDTTRC: 'USDTTRC',
                        TRYP2P: 'TRYP2P',
                        INRP2P: 'INRP2P',
                        UZSP2P: 'UZSP2P',
                        KZTP2P: 'KZTP2P',
                        CARDRUBP2P: 'CARDRUBP2P',
                        GBPP2P: 'GBPP2P',
                        EURP2P: 'EURP2P',
                        AZNP2P: 'AZNP2P',
                        BTC: 'BTC',
                        BCH: 'BCH',
                        ETH: 'ETH',
                        XRP: 'XRP',
                        LTC: 'LTC',
                        ADA: 'ADA',
                        DASH: 'DASH',
                        DOGE: 'DOGE',
                        XMR: 'XMR',
                        TRX: 'TRX',
                        BNB: 'BNB',
                        USDCTRC: 'USDCTRC',
                        XLM: 'XLM',
                        SHIB: 'SHIB',
                        SOL: 'SOL',
                        MATIC: 'MATIC',
                        ETC: 'ETC',
                        CRO: 'CRO',
                        TONCOIN: 'TONCOIN',
                        USD: 'USD',
                        USDC: 'USDC',
                        BTG: 'BTG',
                        XTZ: 'XTZ',
                        ZEC: 'ZEC',
                        EUR: 'EUR',
                        EOS: 'EOS',
                        DAIERC: 'DAIERC',
                        TUSD: 'TUSD',
                        USDP: 'USDP',
                        BNB2: 'BNB2',
                        USDTBEP: 'USDTBEP',
                        LINK: 'LINK',
                        GBP: 'GBP',
                        AZN: 'AZN',
                        CARDRUB: 'CARDRUB',
                        KZT: 'KZT',
                        UZS: 'UZS',
                        TRY: 'TRY',
                        INR: 'INR',
                        IDR: 'IDR',
                        10: '10',
                        50: '50',
                        100: '100',
                        200: '200',
                        'GMT-0': 'GMT-0',
                        'GMT-1': 'GMT-1',
                        'GMT-2': 'GMT-2',
                        'GMT-3': 'GMT-3',
                        'GMT-4': 'GMT-4',
                        'GMT-5': 'GMT-5',
                        'GMT-6': 'GMT-6',
                        'GMT-7': 'GMT-7',
                        'GMT-8': 'GMT-8',
                        'GMT-9': 'GMT-9',
                        'GMT-10': 'GMT-10',
                        'GMT-11': 'GMT-11',
                        'GMT-12': 'GMT-12',
                        'GMT+1': 'GMT+1',
                        'GMT+2': 'GMT+2',
                        'GMT+3': 'GMT+3',
                        'GMT+4': 'GMT+4',
                        'GMT+5': 'GMT+5',
                        'GMT+6': 'GMT+6',
                        'GMT+7': 'GMT+7',
                        'GMT+8': 'GMT+8',
                        'GMT+9': 'GMT+9',
                        'GMT+10': 'GMT+10',
                        'GMT+11': 'GMT+11',
                        'GMT+12': 'GMT+12',
                        downLoadCSV: 'Download as file',
                        prepareDownload: 'Download',
                        downloadsList: 'Requested files:',
                        toDownLoadCSV: 'OK',
                        downloadPrepares: 'Preparing',
                        Format: 'File format',
                        from: 'From',
                        to: 'To',
                        subUser: 'User',
                        commission: 'Commission',
                        deleteSubUser: 'Delete user',
                        credited: 'Credited',
                        clientMerchantId: 'MID',
                        fingerprint: 'Fingerprint',
                        create2FA: 'Create 2FA',
                        dateFrom: 'Date from',
                        timeFrom: 'Time from',
                        adminAccount: 'Admin account',
                        sorry: 'Sorry',
                        goToOur: 'But you can go to our',
                        notExist: 'but we don`t have such a page',
                        dateTo: 'Date before',
                        timeTo: 'Time before',
                        clientTransactionId: 'External ID',
                        transationId: 'External ID',
                        adressHash: 'Receiver/Hash',
                        adress: 'Receiver',
                        hash: 'Hash',
                        IBAN: 'IBAN',
                        RecipientName: 'Recipient name',
                        AccountPhoneNumber: 'Account number / Phone number',
                        VPA: 'VPA Address',
                        IFSCCode: 'IFSC Code',
                        AccountNumber: 'Account number',
                        BICCode: 'BIC Code',
                        SortCode: 'Sort Code',
                        notSpaces: 'Password must not contain spaces',
                        passwordLength: 'Password must be at least 6 characters long',
                        noRepeat: 'Symbols in password should not be repeated',
                        passwordMustContain: 'The password must contain letters in both cases, numbers and special characters',
                        notMatch: 'Passwords not match',
                        csv: 'csv',
                        xls: 'xls',
                        GreatBritainPound: 'Great Britain Pound',
                        IndianRupee: 'Indian Rupee',
                        KazakhstanTenge: 'Kazakhstan Tenge',
                        TurkishLira: 'Turkish Lira',
                        Euro: 'Euro',
                        UzbekistanSoma: 'Uzbekistan Soma',
                        P2PRUB: 'P2P RUB',
                        P2PAZN: 'P2P AZN',
                        code2FA: '2FA',
                        sessionsList: 'Active sessions',
                        ip: 'IP',
                        userAgent: 'User Agent',
                        signTime: 'Entry time',
                        city: 'City',
                        clearSessions: 'Clear sessions',
                        errorDownload: 'Download error. Please try again',
                        errorTime: 'Please check date and time fields',
                        conversion: 'Conversion',
                        conversions: 'Conversions',
                        description: 'Description',
                        operation: 'Operation',
                        ERR_LK_INVALID_EMAIL: 'Invalid email',
                        ERR_LK_INVALID_INVITE_CODE: 'Invalid invite code',
                        ERR_LK_USED_INVITE_CODE: 'Invite code already used',
                        ERR_LK_EMAIL_ALREADY_EXISTS: 'Email already in use',
                        ERR_LK_WEAK_PASSWORD: 'Weak password',
                        ERR_LK_UNKNOWN_SERVICE: 'Invalid request',
                        ERR_LK_UNKNOWN: 'Unknown error',
                        ERR_LK_USER_NOT_FOUND: 'User not found',
                        ERR_LK_INVALID_PASSWORD: 'Invalid password',
                        ERR_LK_INVALID_OTP: 'Invalid one-time code',
                        ERR_LK_EMAIL_ALREADY_CONFIRMED: 'Email already confirmed',
                        ERR_LK_UNKNOWN_CITY: 'Unknown city',
                        ERR_LK_LOG_USER_SIGN: 'Failed to save user session',
                        ERR_LK_EMAIL_REQUIRED: 'Email required',
                        ERR_LK_SUB_USER_NOT_FOUND: 'Sub-user not found',
                        ERR_WITHDRAWALS_BLOCKED: 'Withdrawals blocked',
                        ERR_WITHDRAWALS_BLOCKED_BY_TOKEN: 'Withdrawals blocked for specific token',
                        ERR_NO_TOKEN_COMMISSION: 'No commission for user on specific token',
                        ERR_PERMISSION_DENIED: 'Permission denied',
                        ERR_INVOICES_BLOCKED: 'Invoices blocked',
                        ERR_INVOICES_BLOCKED_BY_TOKEN: 'Invoices blocked for specific token',
                        ERR_LK_GET_USER_SESSIONS: 'Failed to get user session',
                        ERR_LK_2FA_DISABLED: '2FA disabled',
                        ERR_LK_2FA_ALREADY_ENABLED: '2FA is already turned on',
                        ERR_WRONG_INPUT: 'Incorrect data entered',
                        ERR_LK_EMAIL_NOT_CONFIRMED: 'Email not confirmed',
                        ERR_LK_INVALID_CREDENTIALS: ' Incorrect login/password entered',
                    },
                    accessLevels: {
                        0: 'Access denied',
                        1: 'Read access',
                        2: 'Full access',
                    },
                },
            },
            ru: {
                translation: {
                    components: {
                        status: 'Статус',
                        requisites: 'Реквизиты',
                        send: 'Отправляем',
                        rate: 'Курс',
                        get: 'Получаем',
                        '/': 'Заявки',
                        '/withdraw': 'Баланс',
                        '/settings': 'Настройки',
                        available: 'доступно',
                        freeze: 'заморожено',
                        date: 'Дата',
                        confirm: 'Подтвердить',
                        subUsers: 'Пользователи',
                        dateCreate: 'Дата создания',
                        dateComplete: 'Дата завершения',
                        actions: 'Действия',
                        total: 'Тотал',
                        id: 'ID',
                        access: 'Доступы',
                        enable: 'Сатус',
                        createSubuser: 'Создать пользователя',
                        deleteSubUser: 'Удалить пользователя',
                        successful: 'Успешно',
                        delete: 'Удалить',
                        accepted: 'Обработка',
                        appeal: 'Апелляция',
                        appealSum: 'Сумма апелляции',
                        dropFile: 'Отпустите файл',
                        uploadFile: 'Загрузите файл',
                        dragNDropeFile: 'Перетащите файл',
                        updateSubuser: 'Обновить пользователя',
                        callback: 'Callback',
                        toMain: 'К заявкам',
                        subUser: 'Пользователь',
                        noRequired: 'Не обязательно',
                        canceled: 'Отменено',
                        update: 'Обновить',
                        errorStatus: 'Отменен',
                        balance_access: 'Просмотр баланса',
                        statistics_access: 'Просмотр оборота',
                        transactions_access: 'Просмотр истории баланса',
                        operations_access: 'Просмотр истории операций с балансом',
                        invoice_access: 'Производить операцию пополнения',
                        withdrawal_access: 'Производить операцию вывода',
                        password_access: 'Возможность менять пароль',
                        api_key_access: 'Возможность создавать api ключи',
                        tx_operations_access: 'Операции с транзакциями',
                        orders_access: 'Просмотр истории заявок',
                        noAccess: 'У вас нет доступов к данной странице, обратитесь к администратору',
                        conversion_access: 'Просмотр истории конвертаций',
                        all: 'Все',
                        ALL: 'Все',
                        All: 'Все',
                        mainPage: 'основную страницу',
                        error: 'Ошибка',
                        sorry: 'Извините',
                        notExist: ' но у нас нет такой страницы',
                        noData: 'Нет данных',
                        loading: 'Загрузка',
                        orderId: 'ID заявки',
                        leaveEmpty: 'Заполните, если необходимо изменить',
                        transactionId: 'ID транзакции',
                        fiatAmount: 'Сумма в фиате',
                        receipt: 'Чек',
                        appealMessage: 'Введите текст обращения',
                        inspect: 'Посмотреть',
                        chooseAppealType: 'Сумма',
                        recalc: 'Пересчет',
                        delay: 'Задержка',
                        sendAppeal: 'Отправить',
                        changePassword: 'Сменить пароль',
                        sendOTP: 'Отправить 2FA код',
                        adminAccount: 'Аккаунт администратора',
                        balance: 'Баланс',
                        income: 'Пополнено',
                        outcome: 'Выведено',
                        today: 'за сегодня',
                        buy: 'Покупка',
                        sell: 'Продажа',
                        logout: 'Выйти',
                        main: 'Заявки',
                        settings: 'Настройки',
                        withdraw: 'Вывод',
                        invoice: 'Пополнение',
                        toWithdraw: 'Вывести',
                        toInvoice: 'Пополнить',
                        currency: 'Валюта',
                        chooseBank: 'Выбор банка',
                        balanceOperations: 'Операции с балансом',
                        ok: 'Ок',
                        timezone: 'Часовой пояс',
                        type: 'Тип',
                        created: 'Создан',
                        twofa: '2fa код',
                        cardNumber: 'Номер карты',
                        create2FA: 'Создать 2FA',
                        toFiat: 'Переведите фиат на карту',
                        toWallet: ' на этот кошелек',
                        transfer: 'Переведите ',
                        toRequisites: ' на реквизиты',
                        address: 'Адрес',
                        amount: 'Сумма',
                        noType: 'Без типа',
                        in: 'Ввод',
                        goToOur: 'Но вы можете перейти на нашу',
                        out: 'Вывод',
                        newPass: 'Новый пароль',
                        oldPass: 'Старый пароль',
                        repeatedPass: 'Повторите пароль',
                        APIkeys: 'API ключи',
                        changeAPIkeys: 'Сменить API ключи',
                        successChangeKeys: 'Ключи успешно изменены',
                        except: 'Ожидайте пополнения на указанные реквизиты',
                        click: 'нажмите, чтобы посмотреть',
                        signUp: 'Регистрация',
                        signIn: 'Авторизация',
                        login: 'Логин',
                        password: 'Пароль',
                        save2fa:
                        'Сохраните 2fa код в Google Authenticator для входа в личный кабинет',
                        send2Fa: 'Передайте 2FA пользователю для входа в личный кабинет',
                        invite: 'Инвайт код',
                        successSignUp: 'Регистрация прошла успешно',
                        successCopy: 'Успешно скопировано',
                        errorCopy: 'Не скопировано',
                        success: 'Успешно',
                        loggedIn: 'Вы авторизованы',
                        notLoggedIn: 'Вы не авторизованы',
                        successSignIn: 'Успешная авторизация',
                        successAppeal: 'Заявка на апелляцию успешно создана!',
                        errorAppeal: 'Ошибка при создании апелляции',
                        successCallback: 'Callback успешно отправлен',
                        errorCallback: 'Ошибка при отправке callback',
                        loadingWithdraw: 'Запрос на вывод отправлен',
                        successUpdatePass: 'Пароль успешно обновлен',
                        chooseToken: 'Выберите токен',
                        USDT: 'USDT',
                        RUB: 'RUB',
                        USDTTRC: 'USDTTRC',
                        TRYP2P: 'TRYP2P',
                        INRP2P: 'INRP2P',
                        UZSP2P: 'UZSP2P',
                        KZTP2P: 'KZTP2P',
                        CARDRUBP2P: 'CARDRUBP2P',
                        GBPP2P: 'GBPP2P',
                        EURP2P: 'EURP2P',
                        AZNP2P: 'AZNP2P',
                        BTC: 'BTC',
                        BCH: 'BCH',
                        ETH: 'ETH',
                        XRP: 'XRP',
                        LTC: 'LTC',
                        ADA: 'ADA',
                        DASH: 'DASH',
                        DOGE: 'DOGE',
                        XMR: 'XMR',
                        TRX: 'TRX',
                        BNB: 'BNB',
                        USDCTRC: 'USDCTRC',
                        XLM: 'XLM',
                        SHIB: 'SHIB',
                        SOL: 'SOL',
                        MATIC: 'MATIC',
                        ETC: 'ETC',
                        CRO: 'CRO',
                        TONCOIN: 'TONCOIN',
                        USD: 'USD',
                        USDC: 'USDC',
                        BTG: 'BTG',
                        XTZ: 'XTZ',
                        ZEC: 'ZEC',
                        EUR: 'EUR',
                        EOS: 'EOS',
                        DAIERC: 'DAIERC',
                        TUSD: 'TUSD',
                        USDP: 'USDP',
                        BNB2: 'BNB2',
                        USDTBEP: 'USDTBEP',
                        LINK: 'LINK',
                        GBP: 'GBP',
                        AZN: 'AZN',
                        CARDRUB: 'CARDRUB',
                        KZT: 'KZT',
                        UZS: 'UZS',
                        TRY: 'TRY',
                        INR: 'INR',
                        IDR: 'IDR',
                        10: '10',
                        50: '50',
                        100: '100',
                        200: '200',
                        'GMT-0': 'GMT-0',
                        'GMT-1': 'GMT-1',
                        'GMT-2': 'GMT-2',
                        'GMT-3': 'GMT-3',
                        'GMT-4': 'GMT-4',
                        'GMT-5': 'GMT-5',
                        'GMT-6': 'GMT-6',
                        'GMT-7': 'GMT-7',
                        'GMT-8': 'GMT-8',
                        'GMT-9': 'GMT-9',
                        'GMT-10': 'GMT-10',
                        'GMT-11': 'GMT-11',
                        'GMT-12': 'GMT-12',
                        'GMT+1': 'GMT+1',
                        'GMT+2': 'GMT+2',
                        'GMT+3': 'GMT+3',
                        'GMT+4': 'GMT+4',
                        'GMT+5': 'GMT+5',
                        'GMT+6': 'GMT+6',
                        'GMT+7': 'GMT+7',
                        'GMT+8': 'GMT+8',
                        'GMT+9': 'GMT+9',
                        'GMT+10': 'GMT+10',
                        'GMT+11': 'GMT+11',
                        'GMT+12': 'GMT+12',
                        downLoadCSV: 'Скачать файл',
                        prepareDownload: 'Запросить файл',
                        toDownLoadCSV: 'ОК',
                        downloadsList: 'Запрошенные файлы:',
                        downloadPrepares: 'Готовится',
                        Format: 'Формат файла',
                        from: 'От',
                        to: 'До',
                        commission: 'Комиссия',
                        credited: 'Зачислено',
                        clientMerchantId: 'MID',
                        fingerprint: 'Fingerprint',
                        dateFrom: 'Дата от',
                        timeFrom: 'Время от',
                        dateTo: 'Дата до',
                        timeTo: 'Время до',
                        clientTransactionId: 'Внешний ID',
                        transationId: 'Внешний ID',
                        adressHash: 'Адрес/Хеш',
                        adress: 'Адрес',
                        hash: 'Хеш',
                        IBAN: 'IBAN',
                        RecipientName: 'Recipient name',
                        AccountPhoneNumber: 'Account number / Phone number',
                        VPA: 'VPA Address',
                        IFSCCode: 'IFSC Code',
                        AccountNumber: 'Account number',
                        BICCode: 'BIC Code',
                        SortCode: 'Sort Code',
                        notSpaces: 'Пароль не должен содержать пробелы',
                        passwordLength: 'Пароль должен содержать не менее 6 символов',
                        noRepeat: 'Символы в пароле не должны повторяться',
                        passwordMustContain: 'Пароль должен содержать буквы в обоих регистрах, цифры и специальные символы',
                        notMatch: 'Пароли не совпадают',
                        csv: 'csv',
                        xls: 'xls',
                        GreatBritainPound: 'Great Britain Pound',
                        IndianRupee: 'Indian Rupee',
                        KazakhstanTenge: 'Kazakhstan Tenge',
                        TurkishLira: 'Turkish Lira',
                        Euro: 'Euro',
                        UzbekistanSoma: 'Uzbekistan Soma',
                        P2PRUB: 'P2P RUB',
                        P2PAZN: 'P2P AZN',
                        code2FA: '2FA',
                        sessionsList: 'Активные сессии',
                        ip: 'IP',
                        userAgent: 'User Agent',
                        signTime: 'Время входа',
                        city: 'Город',
                        clearSessions: 'Очистить сессии',
                        errorDownload: 'Ошибка скачивания. Повторите попытку',
                        errorTime: 'Проверьте корректность полей дат и времени',
                        conversion: 'Конвертация',
                        conversions: 'Конвертации',
                        description: 'Описание',
                        operation: 'Операция',
                        ERR_LK_INVALID_EMAIL: 'Неверная почта',
                        ERR_LK_INVALID_INVITE_CODE: 'Неверный инвайт-код',
                        ERR_LK_USED_INVITE_CODE: 'Инвайт-код уже использован',
                        ERR_LK_EMAIL_ALREADY_EXISTS: 'Почта уже использована',
                        ERR_LK_WEAK_PASSWORD: 'Введен слабый пароль',
                        ERR_LK_UNKNOWN_SERVICE: 'Неверный запрос',
                        ERR_LK_UNKNOWN: 'Неизвестная ошибка',
                        ERR_LK_USER_NOT_FOUND: 'Пользователь не найден',
                        ERR_LK_INVALID_PASSWORD: 'Неверный пароль',
                        ERR_LK_INVALID_OTP: 'Неверный одноразовый код',
                        ERR_LK_EMAIL_ALREADY_CONFIRMED: 'Почта уже подтверждена',
                        ERR_LK_UNKNOWN_CITY: 'Неизвестный город',
                        ERR_LK_LOG_USER_SIGN: 'Не удалось сохранить сессию пользователя',
                        ERR_LK_EMAIL_REQUIRED: 'Нужна электронная почта',
                        ERR_LK_SUB_USER_NOT_FOUND: 'Сабюзер не найден',
                        ERR_WITHDRAWALS_BLOCKED: 'Выплаты по токену выключены',
                        ERR_WITHDRAWALS_BLOCKED_BY_TOKEN: 'Выплаты по токену выключены',
                        ERR_NO_TOKEN_COMMISSION: 'Нет комиссии для пользователя',
                        ERR_PERMISSION_DENIED: 'Не хватает прав',
                        ERR_INVOICES_BLOCKED: 'Прием выключен',
                        ERR_INVOICES_BLOCKED_BY_TOKEN: 'Прием по токену выключен',
                        ERR_LK_GET_USER_SESSIONS: 'Не удалось получить сессию пользователя',
                        ERR_LK_2FA_DISABLED: 'Выключена 2фа',
                        ERR_LK_2FA_ALREADY_ENABLED: '2фа уже включена',
                        ERR_WRONG_INPUT: 'Введены некорректные данные',
                        ERR_LK_EMAIL_NOT_CONFIRMED: 'Почта не подтверждена',
                        ERR_LK_INVALID_CREDENTIALS: ' Введен неверный логин/пароль',
                    },
                    accessLevels: {
                        0: 'Доступ запрещен',
                        1: 'Только чтение',
                        2: 'Полный доступ',
                    },
                },
            },
        },
    })

export default i18n
