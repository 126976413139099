import {Typography} from 'antd'

import React, { useState } from 'react'

import QRCode from 'react-qr-code'

import {toast} from 'react-toastify'

import {useTranslation} from 'react-i18next'

import { getCookie } from 'typescript-cookie'

import InputWithLabel from '../input-with-label/input-with-label'
import AccessSelect from '../access-select/AccessSelect'
import Button from '../button/button'

import CopyableText from '../copyAbleText/copyAbleText'
import Popup from '../popup/popup'

import {ACCESSES, LEVELS} from '../../libs/consts'

import {GetPasswordError} from '../../utils/getPasswordError'
import { useConfirmSubuserOtpMutation, useCreateSubUserMutation } from '../../store/services/subusers-service'

const CreateSubUserModal = ({open, setOpen}) => {
    const {t} = useTranslation()
    const notifyError = (error: string) => toast.error(t(`components.${error}`))

    const [subUserSecret, setSubUserSecret] = React.useState('')
    const [subUserOtpUrl, setSubUserOtpUrl] = React.useState('')
    const [subuserLogin, setSubuserLogin] = React.useState('')
    const [subuserPassword, setSubuserPassword] = React.useState('')
    const [userOtp, setUserOtp] = React.useState('')
    const [accesses, setAccesses] = React.useState(ACCESSES)
    const [passwordError, setPasswordError] = React.useState<string | null>(null)
    const [subuserId, setSubuserId] = useState(0)


    const [createSubuser] = useCreateSubUserMutation()
    const [confirmSubuserOtp] = useConfirmSubuserOtpMutation()

    const handleCreateSubUser = () => {
        createSubuser({
            email: subuserLogin,
            password: subuserPassword,
            otp: userOtp,
            gen_otp: true,
            accesses,
        })
        .unwrap()
        .then((res) => {
            toast.success(t('components.success'))
            setSubUserSecret(res?.otp_secret)
            setSubUserOtpUrl(res?.otp_url)
            setSubuserId(res?.subuser_id)
        })
        .catch((e) => {
            if (e?.data?.description) {
              notifyError(e?.data?.description)
            } else {
              notifyError('error')
            }
        })
    }

    const handleSetSubuserOtp = () => {
        confirmSubuserOtp({
            subuser_id: subuserId,
            otp_secret: subUserSecret,
        })
        .unwrap()
        .then((res) => {
                toast.success(t('components.success'))
                setOpen(false)
        })
        .catch((e) => {
            if (e?.data?.description) {
              notifyError(e?.data?.description)
            } else {
              notifyError('error')
            }
        })
    }

    const handleSelectAccess = (id: string, value: { level: string }) => {
        setAccesses((prev) => ({...prev, [id]: Number(value.level)}))
    }

    return (
        <Popup isVisible={open} setIsVisible={setOpen} maxHeight="90vh">
            {!subUserSecret ?
                <div>
                    <Typography style={{
                        fontSize: '18px',
                        color: '#000',
                        fontWeight: '600',
                        textAlign: 'start',
                    }}>
                        {t('components.createSubuser')}
                    </Typography>
                    <InputWithLabel
                        required
                        value={subuserLogin}
                        label="login"
                        onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setSubuserLogin(e.target.value)}
                        placeholder="Login"
                    />
                    <InputWithLabel
                        required
                        name={'password'}
                        value={subuserPassword}
                        label="password"
                        error={passwordError || ''}
                        onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setSubuserPassword(e.target.value)
                            setPasswordError(null)
                            const {error} = GetPasswordError(e.target.value)
                            setPasswordError(error)
                        }}
                        placeholder="Password"
                        type="password"
                    />
                    <InputWithLabel
                        required
                        value={userOtp}
                        label="twofa"
                        onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setUserOtp(e.target.value)}
                        placeholder="2FA"
                    />
                    <div style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'end',
                        gap: '12px',
                        marginTop: '16px',
                    }}>
                        {Object.entries(accesses).map(([key,value], idx) => {
                            
                            if(getCookie('is_fiat') === 'false' && key === 'conversion_access') {
                                return null
                            }

                            return ( <div key={key} style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                background: idx % 2 ? '#fff' : '#F7F7F7',
                                gap: '16px',
                            }}>
                                <Typography style={{
                                    fontSize: '18px',
                                    color: '#000',
                                    fontWeight: '600',
                                    textAlign: 'start',
                                }}>
                                    {t(`components.${key}`)}
                                </Typography>
                                <AccessSelect
                                    options={LEVELS[key]}
                                    id={key}
                                    width="max-content"
                                    height="34px"
                                    label={'timezone'}
                                    selectHandler={handleSelectAccess}
                                    selectedOption={value}
                                    isCreate
                                />
                            </div>
                        )})}
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginTop: '16px',
                    }}>
                        <Button w="max-content" variant="primary"
                                disabled={!subuserLogin || !subuserPassword}
                                onClick={() => handleCreateSubUser()}>
                            {t('components.createSubuser')}
                        </Button>
                    </div>
                </div>
                :
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '16px',
                }}>
                    <QRCode
                        size={400}
                        style={{
                            minWidth: '126px',
                            maxWidth: '45%',
                            height: 'auto',
                            border: '5px solid #fff',
                            marginRight: '10px',
                        }}
                        value={subUserOtpUrl}
                        viewBox={'0 0 100 100'}
                    />
                     <Typography style={{
                            fontSize: '18px',
                            color: '#000',
                            fontWeight: '600',
                            textAlign: 'center',
                        }}>
                            {t('components.send2Fa')}
                        </Typography>
                    <div style={{
                        width: 'max-content',
                        height: 'max-content',
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '6px',
                        border: '1px solid black',
                    }}>
                       <p style={{
                            color: '#000',
                            fontSize: '18px',
                            wordBreak: 'break-all',
                        }}>
                            <CopyableText
                                text={subUserSecret || ''}
                                isFull={true}
                                color="#000"
                            />
                        </p>
                    </div>
                    
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginTop: '16px',
                    }}>
                        <Button w="max-content" variant="primary"
                                onClick={handleSetSubuserOtp}>
                            {t('components.confirm')}
                        </Button>
                    </div>
                </div>
            }
        </Popup>
    )
}

export default CreateSubUserModal
