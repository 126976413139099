import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import QRCode from 'react-qr-code'
import Skeleton from '@mui/material/Skeleton'
import { toast } from 'react-toastify'

import { useTranslation } from 'react-i18next'

import Button from '../../components/button/button'
import CustomSelect from '../../components/custom-select/CustomSelect'
import InputWithLabel from '../../components/input-with-label/input-with-label'
import Popup from '../../components/popup/popup'
import { makeRandomString, rounding_format } from '../../libs/utils/utils'
import { useLazySubTokenFetchQuery } from '../../store/services/tokens-service'
import {
  useCreateInvoiceMutation,
  useCreateWithdrawMutation,
} from '../../store/services/transactions-service'
import { useLazyRefreshQuery } from '../../store/services/base-service'
import CSVBalanceButton from '../csv-balance-button/csv-balance-button'
import CopyableText from '../../components/copyAbleText/copyAbleText'
import { useAppSelector } from '../../hooks/redux'
import { useGetCourseQuery } from '../../store/services/course-service'
import { useTokens } from '../../hooks/use-tokens'
import BankSelect from '../../components/bank-select/BankSelect'



interface ExtraRequisites {
  recipient_name?: string;
  ifsc_code?: string;
  bic_code?: string;
  sort_code?: string;
}

interface InputsInterface {
  otp_code: string;
  amount: number;
  card_number?: string;
  receiver?: string;
  extra_info?: ExtraRequisites;
  dest_tag?: string;
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 24px;
  grid-gap: 20px;

  @media (max-width: 768px) {
    grid-gap: 10px;
  }

  @media (max-width: 535px) {
    grid-template-columns: 1fr;
  }
`

const H3 = styled.h3`
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
`

const Ul = styled.ul`
  display: flex;
  grid-gap: 10px;

  li {
    cursor: pointer;
    border-bottom: 1px solid transparent;

    &.active {
      border-color: black;
    }
  }
`
const FLexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  @media screen and (max-width: 480px) {
		flex-direction: column;
	}
`

const InputWrapper = styled.div`
  margin-top: 20px;
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 20px;
`

const currenciesDefault = [
  { name: 'USDTTRC', type: 'CRYPTO' },
  { name: 'CARDRUBP2P', type: 'P2P' },
]

const typeInOut = {
  IN: 'IN', // invoice
  OUT: 'OUT', // withdraw
}

const inputsInitialState = {
  otp_code: '',
  amount: '',
  card_number: '',
  receiver: '',
  extra_info: undefined,
}

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  @media (max-width: 330px) {
    flex-direction: column;
    justify-self: start;
    align-items: start;
  }
`

// todo: разделить на отдельные фичи, объединить в одном виджете

const InOut = () => {
  const {t} = useTranslation()
  const tokens = useTokens()
  const toastId = React.useRef(null)
  let subTokensArray = []
  // const token = useAppSelector(state => state.currency.token)
  const sortedTokens = tokens?.filter((token) => token.type === 'CRYPTO' || token.type === 'P2P')
  .sort((a, b) => {
    if (a.type === 'P2P' && b.type !== 'P2P') {
      return -1
    }
    if (a.type !== 'P2P' && b.type === 'P2P') {
      return 1
    }
    return 0
  })
  .map((item) => {
    return { name: item.token, type: item.type }
  })

  const currencies = sortedTokens.length > 0 ? sortedTokens : currenciesDefault

  // @ts-ignore
  const notifyLoading = () => toastId.current = toast.info(t('components.loadingWithdraw'), { autoClose: false })
  const notifyError = (error: string) => toast.error(t(`components.${error}`))
  // @ts-ignore
  const notifySuccess = () => toast.update(toastId.current, { render: t('components.success'), type: 'success', autoClose: true})

  const [invoice, setInvoice] = React.useState<{ name: string, type: string }>(
    sortedTokens.length > 0 ? sortedTokens[0] : currenciesDefault[0],
  )
  const [withdraw, setWithdraw] = React.useState<{ name: string, type: string }>(
    sortedTokens.length > 0 ? sortedTokens[0] : currenciesDefault[0],
  )

  const [inputsInvoice, setInputsInvoice] = React.useState<
    InputsInterface | any
  >(inputsInitialState)
  const [inputsWithdraw, setInputsWithdraw] = React.useState<
    InputsInterface | any
  >(inputsInitialState)


  const [fetchSubTokens, { data: dataSubtokens }] = useLazySubTokenFetchQuery()
  const [defaultSubToken, setDefaultSubToken] = React.useState(
    dataSubtokens?.payment_systems.length
      ? dataSubtokens?.payment_systems[0]?.name
      : '',
  )
  const [selectedSubToken, setSelectedSubToken] = React.useState(
    dataSubtokens?.payment_systems.length
      ? dataSubtokens?.payment_systems[0]?.name
      : '',
  )

  const {invoice_access ,withdrawal_access, transactions_access} = useAppSelector((state) => state.base.accesses)
  const isSubUser = useAppSelector((state) => state.base.isSubUser)

  const { data: courseWithdraw } = useGetCourseQuery(withdraw.name)
  const { data: courseInvoice } = useGetCourseQuery(invoice.name)
  const [
    createWithdraw,
    {
      data: dataWithdraw,
      isSuccess: isSuccessCreateWithdraw,
      isLoading: isLoadingCreateWithdraw,
      isError: isErrorCreateWithdraw,
      error: errorWithdraw,
    },
  ] = useCreateWithdrawMutation()
  const [
    createInvoice,
    {
      data: dataInvoice,
      isSuccess: isSuccessCreateInvoice,
      isError: isErrorCreateInvoice,
      error: errorInvoice,
    },
  ] = useCreateInvoiceMutation()

  const [refresh] = useLazyRefreshQuery()

  const [isSuccessCreateWithdrawState, setIsSuccessCreateWithdrawState] =
    React.useState(isSuccessCreateWithdraw)
  const [isSuccessCreateInvoiceState, setIsSuccessCreateInvoiceState] =
    React.useState(isSuccessCreateInvoice)

  React.useEffect(() => {
    if (isLoadingCreateWithdraw) {
      notifyLoading()
    }
  }, [isLoadingCreateWithdraw])

  React.useEffect(() => {
    setInputsInvoice(inputsInitialState)
    setInputsWithdraw(inputsInitialState)
  }, [invoice, withdraw])

  React.useEffect(() => {
    if (isSuccessCreateInvoiceState) {
      notifySuccess()
      clearInputsData()
    } else if (isErrorCreateInvoice) {
      clearInputsData()
    }
  }, [isSuccessCreateInvoiceState, isErrorCreateInvoice])

  React.useEffect(() => {
    if (isSuccessCreateWithdrawState) {
      notifySuccess()
      clearInputsData()
    } else if (isErrorCreateWithdraw) {
      clearInputsData()
    }
  }, [isSuccessCreateWithdrawState, isErrorCreateWithdraw])

  React.useEffect(() => {
    if (errorInvoice) {
      if ('data' in errorInvoice) {
        if (errorInvoice.data === 'Invalid or expired JWT') {
          refresh(null)
        } else return
      }
    }

    if (errorWithdraw) {
      if ('data' in errorWithdraw) {
        if (errorWithdraw.data === 'Invalid or expired JWT') {
          refresh(null)
        } else return
      }
    }
  }, [errorInvoice, errorWithdraw])

  function changeInputs(e: React.ChangeEvent<HTMLInputElement>, type: string) {
    const { name, value } = e.target
    if (type === typeInOut.IN) {
      setInputsInvoice((prev: any) => ({
        ...prev,
        [name]: name === 'amount'? value.replace(',', '.') : value,
      }))
    } else {
      setInputsWithdraw((prev: InputsInterface) => ({
        ...prev,
        [name]: name === 'amount'? value.replace(',', '.') : value,
      }))
    }
  }

  function submitDateHandler(
    e: React.FormEvent<HTMLFormElement>,
    type?: string,
  ) {
    e.preventDefault()
    if (type === typeInOut.IN) {
      if (invoice.type === 'CRYPTO') {
        createInvoice({
          call_back_url: '',
          client_transaction_id: makeRandomString(10),
          otp_code: inputsInvoice?.otp_code,
          token: invoice?.name,
          sub_token: selectedSubToken,
        })
        .unwrap()
        .catch((e) => {
          if (e?.data?.description) {
            notifyError(e?.data?.description)
          } else {
            notifyError('error')
          }
        })
      } else {
        createInvoice({
          amount: +inputsInvoice.amount,
          call_back_url: '',
          client_transaction_id: makeRandomString(10),
          otp_code: inputsInvoice?.otp_code,
          card_number: inputsInvoice?.card_number,
          token: invoice?.name,
          sub_token: selectedSubToken,
        })
        .unwrap()
        .catch((e) => {
          if (e?.data?.description) {
            notifyError(e?.data?.description)
          } else {
            notifyError('error')
          }
        })
      }
    } else {
      if (withdraw?.name === 'CARDRUBP2P') {
        createWithdraw({
          amount: +inputsWithdraw.amount,
          call_back_url: '',
          client_transaction_id: makeRandomString(10),
          otp_code: inputsWithdraw?.otp_code,
          receiver: inputsWithdraw?.receiver,
          token: withdraw?.name,
          sub_token: selectedSubToken,
        })
        .unwrap()
        .catch((e) => {
          if (e?.data?.description) {
            notifyError(e?.data?.description)
          } else {
            notifyError('error')
          }
        })
      } else {
        createWithdraw({
          amount: +inputsWithdraw?.amount,
          call_back_url: '',
          client_transaction_id: makeRandomString(10),
          otp_code: inputsWithdraw?.otp_code,
          receiver: inputsWithdraw?.receiver,
          dest_tag: inputsWithdraw?.dest_tag,
          token: withdraw?.name,
          sub_token: selectedSubToken,
          extra_info: {
            recipient_name: inputsWithdraw?.recipient_name,
            ifsc_code: inputsWithdraw?.ifsc_code,
            bic_code: inputsWithdraw?.bic_code,
            sort_code: inputsWithdraw?.sort_code,
          },
        })
        .unwrap()
        .catch((e) => {
          if (e?.data?.description) {
            notifyError(e?.data?.description)
          } else {
            notifyError('error')
          }
        })
      }
    }
  }
  const [isOutPopupVisible, setIsOutPopupVisible] = React.useState(false)
  const [isInPopupVisible, setIsInPopupVisible] = React.useState(false)

  function clearInputsData() {
    setInputsInvoice(inputsInitialState)
    setInputsWithdraw(inputsInitialState)
  }

  const selectCurrencyBuyHandler = (el: any) => {
    setInvoice(el)
    fetchSubTokens(el.name)
    clearInputsData()
  }

  const selectCurrencySellHandler = (el: any) => {
    setWithdraw(el)
    fetchSubTokens(el.name)
  }

  const selectSubTokenHandler = (el: any) => {
    if (subTokensArray.length > 0) {
      setDefaultSubToken(
        subTokensArray?.find((item: any) => item?.name === el.name) as any,
      )
    }
    setSelectedSubToken(
      dataSubtokens?.payment_systems?.find(
        (item) => item?.full_name === el.name,
      ).name,
    )
  }

  if (dataSubtokens?.payment_systems) {
    subTokensArray = dataSubtokens?.payment_systems.map((item) => {
      return {
        name: item.full_name,
      }
    })
  }

  useEffect(() => {
    setIsSuccessCreateInvoiceState(isSuccessCreateInvoice)
  }, [isSuccessCreateInvoice])

  useEffect(() => {
    setIsSuccessCreateWithdrawState(isSuccessCreateWithdraw)
  }, [isSuccessCreateWithdraw])

  useEffect(() => {
    setSelectedSubToken(
      dataSubtokens?.payment_systems.length
        ? dataSubtokens?.payment_systems[0]?.name
        : '',
    )
  }, [dataSubtokens])

  return (
    <Wrapper>
      <ButtonsWrapper>
          {isSubUser ?
              <>
                {!!withdrawal_access &&
                    <Button onClick={() => {
                      if(withdrawal_access > 1) {
                        setIsOutPopupVisible(true)
                        clearInputsData()
                      }
                    }} variant="secondary">
                      {t('components.toWithdraw')}
                    </Button>
                }

                {!!invoice_access &&
                    <Button onClick={() => {
                      if(invoice_access > 1) {
                        setIsInPopupVisible(true)
                        clearInputsData()
                      }
                    }} variant="primary">
                      {t('components.toInvoice')}
                    </Button>
                }
                </>
                :
              <>
                <Button onClick={() => {
                  setIsOutPopupVisible(true)
                  clearInputsData()
                }} variant="secondary">
                  {t('components.toWithdraw')}
                </Button>
                <Button onClick={() => {
                  setIsInPopupVisible(true)
                  clearInputsData()
                }} variant="primary">
                  {t('components.toInvoice')}
                </Button>
              </>
          }
      </ButtonsWrapper>
      {isSubUser ? !!transactions_access &&
          <CSVBalanceButton />
          :
          <CSVBalanceButton />
      }
      <Popup
        minWidth="450px"
        isVisible={isInPopupVisible}
        setIsVisible={(value) => {
          setIsInPopupVisible(value)
          setInvoice(currencies?.[0])
          setIsSuccessCreateInvoiceState(false)
          setDefaultSubToken('')

        }}
      >
        <form
          onSubmit={(e: any) => submitDateHandler(e, typeInOut.IN)}
          style={{ height: 'min-content' }}
        >
          <H3>
            {t('components.invoice')}
          </H3>
          {!isSuccessCreateInvoiceState && (
            <>
              <FLexBetween>
                <Ul>
                  <CustomSelect
                    options={currencies}
                    width="220px"
                    height="34px"
                    label="currency"
                    selectHandler={selectCurrencyBuyHandler}
                    selectedOption={invoice}
                  />
                </Ul>
                {(invoice.type === 'P2P') && (
                  <Ul>
                    <BankSelect
                      options={subTokensArray}
                      width="220px"
                      height="34px"
                      label="chooseBank"
                      selectHandler={selectSubTokenHandler}
                      selectedOption={defaultSubToken}
                    />
                  </Ul>
                )}
              </FLexBetween>
              <div>
                <InputWrapper>
                  {/* <InputWithLabel
                    required
                    name={'otp_code'}
                    value={inputsInvoice['otp_code']}
                    label="twofa"
                    onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                      changeInputs(e, typeInOut.IN)
                    }
                    placeholder=""
                  /> */}
                  {(invoice.type === 'P2P') && (
                    <>
                      <InputWithLabel
                        required
                        name={'amount'}
                        value={inputsInvoice['amount']}
                        label="amount"
                        onChangeHandler={(
                          e: React.ChangeEvent<HTMLInputElement>,
                        ) =>
                          changeInputs(e, typeInOut.IN)
                        }
                        placeholder="123.45"
                        isP2P={
                          invoice.name.endsWith('P2P')
                          ? courseInvoice?.buy_rate
                          ? String(rounding_format(Number(inputsInvoice['amount']) / courseInvoice?.buy_rate)) !== '0'
                          ? String(rounding_format(Number(inputsInvoice['amount']) / courseInvoice?.buy_rate))
                          : undefined
                          : undefined
                          : undefined}
                      />
                    </>
                  )}
                </InputWrapper>
              </div>
            </>
          )}
          {isSuccessCreateInvoiceState ? (
            <>
              <ButtonWrapper
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                {isSuccessCreateInvoiceState && (invoice.type === 'P2P') && (
                  <div>
                    <p style={{ marginBottom: 10, fontSize: 18 }}>
                      <strong>
                        {t('components.transfer')}
                        {inputsInvoice.amount}
                        {' '}
                        {invoice?.name.replace(/(CARD|P2P)/g, '')}
                        {t('components.toRequisites')}
                      </strong>
                    </p>
                    <p style={{ fontWeight: 'bold', color: '#7154DC', fontSize: 20, textAlign: 'center', margin: '30px 0' }}>
                      <CopyableText text={dataInvoice?.refer} isFull={true} color="#7154DC" />
                    </p>
                    {dataInvoice.extra_info
                      ? Object.entries(dataInvoice.extra_info).map(([label, value]: [string, any]) => {
                        return (
                          <>
                            <p style={{ marginBottom: 5, fontSize: 15, fontWeight: 'bold' }}>
                              {label
                                .split('_')
                                .map(part => part.charAt(0).toUpperCase() + part.slice(1))
                                .join(' ')
                              }:
                            </p>
                            <p style={{ marginBottom: 10, fontSize: 15 }}>{value}</p>
                          </>
                      )
                    })
                    : null}
                  </div>
                )}

                {isSuccessCreateInvoiceState && (invoice.type === 'CRYPTO') && (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '24px 0' }}>
                    <p style={{ marginBottom: 10, fontSize: 18 }}>
                    <strong>{`${t('components.transfer')} ${invoice?.name} ${t('components.toWallet')}`}</strong>
                    </p>
                    <p
                      style={{
                        fontWeight: 'bold',
                        fontSize: window.innerWidth > 331 ? window.innerWidth > 600 ? 16 : 13.5 : 10,
                        lineHeight: 2,
                        color: '#7154DC',
                        wordBreak: 'break-all',
                      }}
                    >
                      <CopyableText text={dataInvoice?.refer} isFull={true} isWrap={true} color="#7154DC" />
                    </p>
                    <QRCode
                      size={400}
                      // className="qrStyle"
                      style={{ minWidth: '126px', maxWidth: '45%', height: 'auto', margin: 'auto' }}
                      value={dataInvoice?.refer}
                      viewBox={'0 0 100 100'}
                    />
                    <p
                        style={{
                          fontWeight: 'bold',
                          fontSize: 16,
                          overflowX: 'scroll',
                          lineHeight: 3,
                          color: '#7154DC',
                        }}
                    >
                      {dataInvoice?.dest_tag?.length ? `Dest_tag: ${dataInvoice?.dest_tag}` : ''}
                    </p>
                  </div>
                )}
                <div style={{ display: 'flex' }}>
                  <Button
                    style={{ marginLeft: 'auto' }}
                    onClick={() => {
                      setIsInPopupVisible(false)
                      setInvoice(currencies?.[0])
                      setDefaultSubToken(
                        dataSubtokens?.payment_systems.length
                          ? dataSubtokens?.payment_systems[0]?.name
                          : '',
                      )
                      setIsSuccessCreateInvoiceState(false)
                      setSelectedSubToken('')
                      clearInputsData()
                    }}
                    variant={'primary'}
                  >
                    {t('components.ok')}
                  </Button>
                </div>
              </ButtonWrapper>
            </>
          ) : (
            <ButtonWrapper>
              <Button variant="primary">
                {t('components.toInvoice')}
              </Button>
            </ButtonWrapper>
          )}
        </form>
      </Popup>

      <Popup
        minWidth="450px"
        isVisible={isOutPopupVisible}
        setIsVisible={(value) => {
          setIsOutPopupVisible(value)
          setInvoice(currencies?.[0])
          setIsSuccessCreateWithdrawState(false)
          setDefaultSubToken(
            dataSubtokens?.payment_systems.length
              ? dataSubtokens?.payment_systems[0]?.name
              : '',
          )
        }}
      >
        <form onSubmit={(e: any) => submitDateHandler(e, typeInOut.OUT)}>
          <H3>
            {t('components.withdraw')}
          </H3>
          <FLexBetween>
            <Ul>
              <CustomSelect
                options={currencies}
                width="220px"
                height="34px"
                label="currency"
                selectHandler={selectCurrencySellHandler}
                selectedOption={withdraw}
              />
            </Ul>
            {(withdraw.type === 'P2P') && (
              <Ul>
                <BankSelect
                  options={subTokensArray}
                  width="220px"
                  height="34px"
                  label="chooseBank"
                  selectHandler={selectSubTokenHandler}
                  selectedOption={defaultSubToken}
                />
              </Ul>
            )}
          </FLexBetween>
          <div>
            <InputWrapper>
              <InputWithLabel
                required
                name={'amount'}
                value={inputsWithdraw['amount']}
                label="amount"
                onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                  changeInputs(e, typeInOut.OUT)
                }
                placeholder="123.45"
                isP2P={withdraw.name.endsWith('P2P')
                ? courseWithdraw?.sell_rate
                ? String(rounding_format(Number(inputsWithdraw['amount']) / courseWithdraw?.sell_rate)) !== '0'
                ? String(rounding_format(Number(inputsWithdraw['amount']) / courseWithdraw?.sell_rate))
                : undefined
                : undefined
                : undefined}
              />
              {withdraw?.name === 'TRYP2P' && selectedSubToken === 'PPRTRY' ? (
                <InputWithLabel
                  required
                  name={'receiver'}
                  value={inputsWithdraw['receiver']}
                  label="AccountPhoneNumber"
                  onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                    changeInputs(e, typeInOut.OUT)
                  }
                  placeholder=""
                />
              ) : withdraw?.name === 'TRYP2P' &&
                (selectedSubToken === 'ZRTTRY' ||
                  selectedSubToken === 'DNZTRY' ||
                  selectedSubToken === 'VKFTRY') ? (
                <>
                  <InputWithLabel
                    required
                    name={'receiver'}
                    value={inputsWithdraw['receiver']}
                    label="IBAN"
                    onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                      changeInputs(e, typeInOut.OUT)
                    }
                    placeholder=""
                  />
                  <InputWithLabel
                    required
                    name={'recipient_name'}
                    value={inputsWithdraw['recipient_name']}
                    label="RecipientName"
                    onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                      changeInputs(e, typeInOut.OUT)
                    }
                    placeholder=""
                  />
                </>
              ) : withdraw?.name === 'INRP2P' &&
                selectedSubToken === 'UPIINR' ? (
                <>
                  <InputWithLabel
                    required
                    name={'receiver'}
                    value={inputsWithdraw['receiver']}
                    label="VPA"
                    onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                      changeInputs(e, typeInOut.OUT)
                    }
                    placeholder=""
                  />
                  <InputWithLabel
                    required
                    name={'recipient_name'}
                    value={inputsWithdraw['recipient_name']}
                    label="RecipientName"
                    onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                      changeInputs(e, typeInOut.OUT)
                    }
                    placeholder=""
                  />
                </>
              ) : withdraw?.name === 'INRP2P' &&
                (selectedSubToken === 'PHPEINR' ||
                  selectedSubToken === 'PAYTMINR') ? (
                <InputWithLabel
                  required
                  name={'receiver'}
                  value={inputsWithdraw['receiver']}
                  label="VPA"
                  onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                    changeInputs(e, typeInOut.OUT)
                  }
                  placeholder=""
                />
              ) : withdraw?.name === 'INRP2P' &&
                (selectedSubToken === 'IMPSINR' ||
                  selectedSubToken === 'NEFTINR' ||
                  selectedSubToken === 'RTGSINR') ? (
                <>
                  <InputWithLabel
                    required
                    name={'receiver'}
                    value={inputsWithdraw['receiver']}
                    label="AccountNumber"
                    onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                      changeInputs(e, typeInOut.OUT)
                    }
                    placeholder=""
                  />
                  <InputWithLabel
                    required
                    name={'ifsc_code'}
                    value={inputsWithdraw['ifsc_code']}
                    label="IFSCCode"
                    onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                      changeInputs(e, typeInOut.OUT)
                    }
                    placeholder=""
                  />
                  <InputWithLabel
                    required
                    name={'recipient_name'}
                    value={inputsWithdraw['recipient_name']}
                    label="RecipientName"
                    onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                      changeInputs(e, typeInOut.OUT)
                    }
                    placeholder=""
                  />
                </>
              ) : withdraw?.name === 'XRP' || withdraw?.name === 'XLM' || withdraw?.name === 'XMR' ? (
                  <>
                  <InputWithLabel
                      required
                      name={'receiver'}
                      value={inputsWithdraw['receiver']}
                      label={`${invoice.type === 'CRYPTO' ? 'address' : 'cardNumber'
                      }`}
                      onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                          changeInputs(e, typeInOut.OUT)
                      }
                      placeholder=""
                  />
                  <InputWithLabel
                      required
                      name={'dest_tag'}
                      value={inputsWithdraw['dest_tag']}
                      label={'dest_tag'}
                      onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                          changeInputs(e, typeInOut.OUT)
                      }
                      placeholder=""
                  />
                  </>
              ) : withdraw?.name === 'GBPP2P' &&
                selectedSubToken === 'SEPAGBP' ? (
                <>
                  <InputWithLabel
                    required
                    name={'receiver'}
                    value={inputsWithdraw['receiver']}
                    label="IBAN"
                    onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                      changeInputs(e, typeInOut.OUT)
                    }
                    placeholder=""
                  />
                  <InputWithLabel
                    required
                    name={'bic_code'}
                    value={inputsWithdraw['bic_code']}
                    label="BICCode"
                    onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                      changeInputs(e, typeInOut.OUT)
                    }
                    placeholder=""
                  />
                  <InputWithLabel
                    required
                    name={'recipient_name'}
                    value={inputsWithdraw['recipient_name']}
                    label="RecipientName"
                    onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                      changeInputs(e, typeInOut.OUT)
                    }
                    placeholder=""
                  />
                </>
              ) : withdraw?.name === 'EURP2P' &&
                selectedSubToken === 'SEPAEUR' ? (
                <>
                  <InputWithLabel
                    required
                    name={'receiver'}
                    value={inputsWithdraw['receiver']}
                    label="IBAN"
                    onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                      changeInputs(e, typeInOut.OUT)
                    }
                    placeholder=""
                  />
                  <InputWithLabel
                    required
                    name={'sort_code'}
                    value={inputsWithdraw['sort_code']}
                    label="SortCode"
                    onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                      changeInputs(e, typeInOut.OUT)
                    }
                    placeholder=""
                  />
                  <InputWithLabel
                    required
                    name={'recipient_name'}
                    value={inputsWithdraw['recipient_name']}
                    label="RecipientName"
                    onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                      changeInputs(e, typeInOut.OUT)
                    }
                    placeholder=""
                  />
                </>
              ) : (
                <InputWithLabel
                  required
                  name={'receiver'}
                  value={inputsWithdraw['receiver']}
                  label={`${invoice.type === 'CRYPTO' ? 'address' : 'cardNumber'
                    }`}
                  onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                    changeInputs(e, typeInOut.OUT)
                  }
                  placeholder=""
                />
              )}
              <InputWithLabel
                required
                name={'otp_code'}
                value={inputsWithdraw['otp_code']}
                label="twofa"
                onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                  changeInputs(e, typeInOut.OUT)
                }
                placeholder=""
              />
            </InputWrapper>
            {isSuccessCreateWithdrawState ? (
              // <ButtonWrapper>
              <>
                <div style={{ padding: '24px 0' }}>
                  <p style={{ marginBottom: 10, fontSize: 16 }}>
                    <strong>{t('components.except')}</strong>
                  </p>
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: window.innerWidth > 331 ? window.innerWidth > 415 ? 16 : 13.5 : 10,
                      overflowX: 'scroll',
                      lineHeight: 3,
                      color: '#7154DC',
                    }}
                  >
                    {dataWithdraw?.receiver}
                  </p>
                </div>

                <div style={{ display: 'flex' }}>
                  <Button
                    style={{ marginLeft: 'auto' }}
                    onClick={() => {
                      setIsOutPopupVisible(false)
                      setInvoice(currencies?.[0])
                      setDefaultSubToken(
                        dataSubtokens?.payment_systems.length
                          ? dataSubtokens?.payment_systems[0]?.name
                          : '',
                      )
                      setIsSuccessCreateWithdrawState(false)
                      setSelectedSubToken('')
                      clearInputsData()
                    }}
                    variant={'primary'}
                  >
                    {t('components.ok')}
                  </Button>
                </div>
              </>
            ) : (
              // </ButtonWrapper>
              <ButtonWrapper>
                <Button disabled={isLoadingCreateWithdraw} variant={'primary'}>
                  {t('components.toWithdraw')}
                </Button>
              </ButtonWrapper>
            )}
          </div>
        </form>
      </Popup>
    </Wrapper>
  )
}

export default React.memo(InOut)
