import React from 'react'
import styled from 'styled-components/macro'
import {toast} from 'react-toastify'
import Skeleton from '@mui/material/Skeleton'
import {useTranslation} from 'react-i18next'

import {Typography} from 'antd'

import access from '../../assets/images/Accesses.svg'

import Button from '../../components/button/button'
import Container from '../../components/container/container'
import InputWithLabel from '../../components/input-with-label/input-with-label'
import {useUpdatePasswordMutation} from '../../store/services/base-service'
import {
    useClearSessionsMutation,
    useGetApiKeysMutation,
    useGetSessionsIntervalQuery,
    useGetSessionsMutation,
    useUpdateApiKeysMutation,
} from '../../store/services/apikeys-service'
import Copy from '../../components/copy/copy'
import Pagination from '../../components/pagination/pagination'
import SettingsCard from '../../components/settings-card/SettingsCard'
import CopyableText from '../../components/copyAbleText/copyAbleText'
import Table from '../../components/table/Table'
import EditSubUserModal from '../../components/modals/EditSubuserModal'
import {useAppDispatch} from '../../hooks/redux'
import {setSubUserData} from '../../store/slices/base-slice'
import DeleteSubUserModal from "../../components/modals/DeleteSubUserModal";
import CreateSubUserModal from "../../components/modals/CreateSubUserModal";
import SubuserCard from '../../components/subuser-card/SubuserCard'
import { useGetSubUsersQuery } from '../../store/services/subusers-service'

const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0;
  font-weight: 400;
  font-size: 17px;
  line-height: 140%;
  color: #1B2431;

  thead {
    background: #E8E9EB;

    > tr {
      > th {
        padding: 10px 24px;
        text-align: start;
        font-size: 18.5px;
        height: 55px;
      }
    }
  }

  tbody {
    background-color: #fff;

    > tr {
      > td {
        padding: 0 10px 10px;
        margin-right: 10px;
        text-align: center;

        * > div {
          > span {
            font-size: 14px;
            color: #f2f2f2;
          }
        }
      }
    }
  }
`
const InputWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 0 32px 28px 32px;;
`

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const TablesWrapper = styled.div`
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
`

const CopyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`


interface SettingsProps {
}

interface Props {
    onClick: () => void
}

const Hidekey: React.FC<Props> = ({onClick}) => {
    const {t} = useTranslation()
    return (
        <CopyWrapper onClick={onClick}>
            <strong style={{ color: 'grey'}}>{t('components.click')}</strong>
        </CopyWrapper>
    )
}


const Settings: React.FC<SettingsProps> = () => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const notifyError = (error: string) => toast.error(t(`components.${error}`))
    const notifySuccess = () => toast.success(t('components.success'))
    const notifySuccessUpdatePass = () => toast.success(t('components.successUpdatePass'))
    const notifySuccessChangeKeys = () => toast.success(t('components.successChangeKeys'))
    const windowWidth = window.innerWidth

    const [changePasswordData, setChangePasswordData] = React.useState<{
        otp_code: string,
        old_password: string,
        password: string,
        repeated_password: string
    }>({
        otp_code: '',
        old_password: '',
        password: '',
        repeated_password: '',
    })
    const [OTP, setOTP] = React.useState<string>('')
    const [isShow, setIsShow] = React.useState(false)
    const [isShowApiKeys, setIsShowApiKeys] = React.useState(false)
    const [isShowUpdatedKeys, setIsShowUpdatedKeys] = React.useState(false)
    const [isVisibleUpdate, setIsVisibleUpdate] = React.useState(false)
    const sessionLimit = 10
    const subuserLimit = 10
    const [currentPage, setCurrentPage] = React.useState(1)
    const [currentPageSubuser, setCurrentPageSubuser] = React.useState(1)
    const [isUpdate, setIsUpdate] = React.useState(false)
    const [passwordConfirmationError, setPasswordConfirmationError] = React.useState<string | null>(null)
    const [passwordError, setPasswordError] = React.useState<string | null>(null)
    const disabled = !changePasswordData.old_password || !changePasswordData.password || !changePasswordData.repeated_password || !changePasswordData.otp_code || passwordError || passwordConfirmationError
    const [createSubuserOpen, setCreateSubuserOpen] = React.useState(false)
    const [deleteSubuserOpen, setDeleteSubuserOpen] = React.useState(false)

    const [editSubuserOpen, setEditSubuserOpen] = React.useState(false)

    React.useEffect(() => {
        if (isShow) {
            const showTimeout = setTimeout(() => {
                setIsShow(false)
            }, 7000)
            return () => clearTimeout(showTimeout)
        }
    }, [isShow])

    const isPasswordConfirmationError = (passwordConfirmation: string): string | null => {
        if (passwordConfirmation.length === 0) {
            return null
        }
        if (passwordConfirmation !== changePasswordData.password) {
            return 'notMatch'
        }
        return null
    }

    const isPasswordError = (password: string): { error: string | null } => {
        const hasSpaces = /\s/
        if (hasSpaces.test(password)) {
            return {error: 'notSpaces'}
        }

        if (password.length === 0) {
            return {error: null}
        }

        if (password.length < 6) {
            return {error: 'passwordLength'}
        }

        const hasUppercase = /[A-Z]/
        const hasLowercase = /[a-z]/
        const hasDigit = /\d/
        const hasSpecial = /[!@#$%^&*()_+\-=[\]{};':"|,.<>/?]+/

        if (!(hasUppercase.test(password) && hasLowercase.test(password) && hasDigit.test(password) && hasSpecial.test(password))) {
            return {error: 'passwordMustContain'}
        }

        return {error: null}
    }

    const [updatePassword, {
        isSuccess: isSuccessUpdatePassword,
    }] = useUpdatePasswordMutation()
    const [checkOTP, {data: apiKeys, isSuccess: isSuccessCheckOTP }] = useGetApiKeysMutation()
    const [updateApiKeys, {
        data: newApiKeys,
        isSuccess: isSuccessUpdateKeys,
    }] = useUpdateApiKeysMutation()
    const {data, isLoading, isFetching} = useGetSessionsIntervalQuery(null, {pollingInterval: 15000})
    const {
        data: dataSubusers,
        isLoading: isLoadingSubuser
    } = useGetSubUsersQuery({offset: (currentPageSubuser - 1) * subuserLimit, limit: 20}, {pollingInterval: 15000})
    const [getSessions, {data: dataSessions, isLoading: isLoadingSessions}] = useGetSessionsMutation()
    const [clearSessions, {
        isSuccess: isSuccessClearSessions,
    }] = useClearSessionsMutation()

    React.useEffect(() => {
        if (isFetching) {
            setIsUpdate(false)
        }
    }, [isFetching])

    const changePasswordHandler = (e: React.FormEvent) => {
        if (e) {
            e.preventDefault()
        }
        updatePassword(changePasswordData)
        .unwrap()
        .catch((e) => {
            if (e?.data?.description) {
              notifyError(e?.data?.description)
            } else {
              notifyError('error')
            }
        })
    }
    const viewOTPHandler = (e: React.FormEvent) => {
        if (e) {
            e.preventDefault()
        }
        if (OTP) {
            checkOTP({otp_code: OTP.replace(/\s+/g, '')})
            .unwrap()
            .catch((e) => {
                if (e?.data?.description) {
                  notifyError(e?.data?.description)
                } else {
                  notifyError('error')
                }
            })
        } 
    }

    const changeAPIkeys = (e: React.FormEvent) => {
        if (e) {
            e.preventDefault()
        }
        updateApiKeys({otp_code: OTP.replace(/\s+/g, '')})
        .unwrap()
        .catch((e) => {
            if (e?.data?.description) {
              notifyError(e?.data?.description)
            } else {
              notifyError('error')
            }
        })
    }

    const handleClearSessions = (e: React.FormEvent) => {
        if (e) {
            e.preventDefault()
        }

        clearSessions(null)
        .unwrap()
        .catch((e) => {
            if (e?.data?.description) {
              notifyError(e?.data?.description)
            } else {
              notifyError('error')
            }
        })

        getSessions(null)
        .unwrap()
        .catch((e) => {
            if (e?.data?.description) {
              notifyError(e?.data?.description)
            } else {
              notifyError('error')
            }
        })

        setIsUpdate(true)
        getSessions(null)
        .unwrap()
        .catch((e) => {
            if (e?.data?.description) {
              notifyError(e?.data?.description)
            } else {
              notifyError('error')
            }
        })
    }

    // const { data: apiKeys } = useGetApiKeysQuery(null)

    const startIndex = (currentPage - 1) * sessionLimit
    const startIndexSubuser = (currentPageSubuser - 1) * subuserLimit

    const dataToUse = isUpdate ? dataSessions : data
    const visibleRows = dataToUse?.sessions?.slice(startIndex, startIndex + sessionLimit)
    const visibleRowsSubusers = dataSubusers?.slice(startIndexSubuser, startIndexSubuser + sessionLimit)

    const keysList = React.useMemo(() => {
        if (!apiKeys) {
            return [{key_name: '', key: ''}]
        }
        return Object.entries(apiKeys).map(arr => {
            return {
                key_name: arr[0],
                key: arr[1],
            }
        })
    }, [apiKeys, isSuccessCheckOTP])

    const newKeysList = React.useMemo(() => {
        if (!newApiKeys) {
            return [{key_name: '', key: ''}]
        }
        return Object.entries(newApiKeys).map(arr => {
            return {
                key_name: arr[0],
                key: arr[1],
            }
        })
    }, [newApiKeys, isSuccessUpdateKeys])

    React.useEffect(() => {
        if (isSuccessUpdatePassword) {
            setChangePasswordData({
                otp_code: '',
                old_password: '',
                password: '',
                repeated_password: '',
            })
        }
        if (isSuccessCheckOTP) {
            setOTP('')
        }
        if (isSuccessUpdateKeys) {
            setOTP('')
        }

    }, [isSuccessUpdatePassword, isSuccessCheckOTP, isSuccessUpdateKeys])

    React.useEffect(() => {
        if (isSuccessUpdatePassword) {
            notifySuccessUpdatePass()
        }
    }, [isSuccessUpdatePassword])

    React.useEffect(() => {
        if (isSuccessCheckOTP) {
            setIsShowUpdatedKeys(false)
            setIsShowApiKeys(true)
            setIsVisibleUpdate(true)
            notifySuccess()
        }
    }, [isSuccessCheckOTP])

    React.useEffect(() => {
        if (isSuccessUpdateKeys) {
            setIsShowApiKeys(false)
            setIsShowUpdatedKeys(true)
            notifySuccessChangeKeys()
        }
    }, [isSuccessUpdateKeys])

    React.useEffect(() => {
        if (isSuccessClearSessions) {
            notifySuccess()
        }
    }, [isSuccessClearSessions])


    return (
        <>
        <Container>
            <TablesWrapper>
                <StyledTable>
                    <thead>
                    <tr>
                        <th>{t('components.changePassword')}:</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <Content>
                                <InputWrapper>
                                    <InputWithLabel
                                        required
                                        name={'old_password'}
                                        value={changePasswordData.old_password}
                                        label="oldPass"
                                        onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setChangePasswordData({
                                                ...changePasswordData,
                                                old_password: e.target.value,
                                            })
                                        }}
                                        placeholder=""
                                        type="password"
                                        width={windowWidth >= 685 ? '540px' : '380px'}
                                        height="44px"
                                        fontSize="27px"
                                        border="1px solid #9c9c9c"
                                        boxShadow="0 0 2px #9c9c9c"
                                        boxShadowHover="0 0 4px #9c9c9c"
                                        boxShadowFocus="0 0 3px #9370d8"
                                    />
                                    <InputWithLabel
                                        required
                                        name={'password'}
                                        value={changePasswordData.password}
                                        label="newPass"
                                        error={passwordError || ''}
                                        onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setChangePasswordData({
                                                ...changePasswordData,
                                                password: e.target.value,
                                            })
                                            setPasswordError(null)
                                            const {error} = isPasswordError(e.target.value)
                                            setPasswordError(error)
                                        }}
                                        placeholder=""
                                        type="password"
                                        width={windowWidth >= 685 ? '540px' : '380px'}
                                        height="44px"
                                        fontSize="27px"
                                        border="1px solid #9c9c9c"
                                        boxShadow="0 0 2px #9c9c9c"
                                        boxShadowHover="0 0 4px #9c9c9c"
                                        boxShadowFocus="0 0 3px #9370d8"
                                    />
                                    <InputWithLabel
                                        required
                                        name={'repeated_password'}
                                        value={changePasswordData.repeated_password}
                                        label="repeatedPass"
                                        error={passwordConfirmationError || ''}
                                        onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setChangePasswordData({
                                                ...changePasswordData,
                                                repeated_password: e.target.value,
                                            })
                                            setPasswordConfirmationError(null)
                                            setPasswordConfirmationError(isPasswordConfirmationError(e.target.value))
                                        }}
                                        placeholder=""
                                        type="password"
                                        width={windowWidth >= 685 ? '540px' : '380px'}
                                        height="44px"
                                        fontSize="27px"
                                        border="1px solid #9c9c9c"
                                        boxShadow="0 0 2px #9c9c9c"
                                        boxShadowHover="0 0 4px #9c9c9c"
                                        boxShadowFocus="0 0 3px #9370d8"
                                    />
                                    <InputWithLabel
                                        required
                                        name={'otp_code'}
                                        value={changePasswordData.otp_code}
                                        label="code2FA"
                                        onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setChangePasswordData({
                                                ...changePasswordData,
                                                otp_code: e.target.value,
                                            })
                                        }}
                                        placeholder=""
                                        type="password"
                                        width={windowWidth >= 685 ? '540px' : '380px'}
                                        height="44px"
                                        fontSize="27px"
                                        border="1px solid #9c9c9c"
                                        boxShadow="0 0 2px #9c9c9c"
                                        boxShadowHover="0 0 4px #9c9c9c"
                                        boxShadowFocus="0 0 3px #9370d8"
                                    />
                                </InputWrapper>
                                <ButtonsWrapper>
                                    <Button disabled={disabled} variant="primary"
                                            onClick={changePasswordHandler}>
                                        {t('components.changePassword')}
                                    </Button>
                                </ButtonsWrapper>
                            </Content>
                        </td>
                    </tr>
                    </tbody>
                </StyledTable>
                <StyledTable>
                    <thead>
                    <tr>
                        <th>{t('components.APIkeys')}:</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <Content>
                                <InputWrapper>
                                    <InputWithLabel
                                        required
                                        name={'OTP'}
                                        value={OTP}
                                        label="code2FA"
                                        onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setOTP(e.target.value)
                                        }}
                                        placeholder=""
                                        type="password"
                                        width="330px"
                                        fontSize="21px"
                                        border="1px solid #9c9c9c"
                                        boxShadow="0 0 2px #9c9c9c"
                                        boxShadowHover="0 0 4px #9c9c9c"
                                        boxShadowFocus="0 0 3px #9370d8"
                                        autocomplete="off"
                                    />
                                </InputWrapper>
                                <ButtonsWrapper>
                                    {isVisibleUpdate ? (
                                        <>
                                            <Button disabled={!OTP} w="120px" small="true" variant="primary"
                                                    onClick={viewOTPHandler}>
                                                {t('components.sendOTP')}
                                            </Button>
                                            <Button disabled={!OTP} w="120px" small="true" variant="secondary"
                                                    onClick={changeAPIkeys}>
                                                {t('components.changeAPIkeys')}
                                            </Button>
                                        </>
                                    ) : (
                                        <Button disabled={!OTP} variant="primary" onClick={viewOTPHandler}>
                                            {t('components.sendOTP')}
                                        </Button>
                                    )}
                                </ButtonsWrapper>
                            </Content>
                            {isShowApiKeys && keysList?.map((obj, index: number) => {
                                return (
                                    <tr key={index} style={{fontSize: '18px'}}>
                                        <td style={{paddingTop: '10px', display: 'flex', alignItems: 'center'}}>
                                            <strong>{obj.key_name}</strong>
                                        </td>
                                        <td style={{
                                            paddingLeft: '10px',
                                            paddingTop: '10px',
                                            wordWrap: 'break-word',
                                            maxWidth: '620px',
                                        }}>
                                        <span>
                                            {
                                                obj.key_name === 'private_key' ?
                                                    isShow ?
                                                        <span style={{
                                                            display: 'flex',
                                                            flexWrap: 'nowrap',
                                                            alignItems: 'center',
                                                        }}>
                                                    <Copy children={<CopyWrapper>{obj.key}</CopyWrapper>}/>
                                                            {/* @ts-ignore */}
                                                            {obj.key &&
                                                                <CopyableText text={obj.key} color="black"
                                                                                isNotVisible={true}/>}
                                                </span>
                                                        : <span style={{
                                                            display: 'flex',
                                                            flexWrap: 'nowrap',
                                                            alignItems: 'center',
                                                        }}>
                                                    <Hidekey onClick={() => setIsShow(true)}/>
                                                            {/* @ts-ignore */}
                                                            {obj.key && <CopyableText text={obj.key} color="grey"
                                                                                        isNotVisible={true}/>}
                                                    </span>
                                                    : <span style={{
                                                        color: '#7154DC',
                                                        display: 'flex',
                                                        flexWrap: 'nowrap',
                                                        alignItems: 'center',
                                                    }}>
                                                    <Copy children={<CopyWrapper>{obj.key}</CopyWrapper>}/>
                                                        {/* @ts-ignore */}
                                                        {obj.key && <CopyableText text={obj.key} color="#7154DC"
                                                                                    isNotVisible={true}/>}
                                                    </span>
                                            }
                                        </span>
                                        </td>
                                    </tr>
                                )
                            })}
                            {isShowUpdatedKeys && newKeysList?.map((obj, index: number) => {
                                return (
                                    <tr key={index} style={{fontSize: '18px'}}>
                                        <td style={{paddingTop: '10px', display: 'flex', alignItems: 'center'}}>
                                            <strong>{obj.key_name}</strong>
                                        </td>
                                        <td style={{
                                            paddingLeft: '10px',
                                            paddingTop: '10px',
                                            wordWrap: 'break-word',
                                            maxWidth: '620px',
                                        }}>
                                    <span>
                                        {
                                            obj.key_name === 'private_key' ?
                                                isShow ?
                                                    <span style={{
                                                        display: 'flex',
                                                        flexWrap: 'nowrap',
                                                        alignItems: 'center',
                                                    }}>
                                                <Copy children={<CopyWrapper>{obj.key}</CopyWrapper>}/>
                                                        {/* @ts-ignore */}
                                                        {obj.key && <CopyableText text={obj.key} color="black"
                                                                                    isNotVisible={true}/>}
                                            </span>
                                                    : <span style={{
                                                        display: 'flex',
                                                        flexWrap: 'nowrap',
                                                        alignItems: 'center',
                                                    }}>
                                                <Hidekey onClick={() => setIsShow(true)}/>
                                                        {/* @ts-ignore */}
                                                        {obj.key && <CopyableText text={obj.key} color="grey"
                                                                                    isNotVisible={true}/>}
                                                </span>
                                                : <span style={{
                                                    color: '#7154DC',
                                                    display: 'flex',
                                                    flexWrap: 'nowrap',
                                                    alignItems: 'center',
                                                }}>
                                                <Copy children={<CopyWrapper>{obj.key}</CopyWrapper>}/>
                                                    {/* @ts-ignore */}
                                                    {obj.key && <CopyableText text={obj.key} color="#7154DC"
                                                                                isNotVisible={true}/>}
                                                </span>
                                        }
                                    </span>
                                        </td>
                                    </tr>
                                )
                            })}
                        </td>
                    </tr>
                    </tbody>
                </StyledTable>
                <StyledTable>
                    <thead>
                        <tr>
                            <th>{t('components.subUsers')}:</th>
                        </tr>
                    </thead>
                    <tbody style={{background: '#f2f2f2'}}>
                        {windowWidth <= 768 && (
                            isLoadingSubuser ? (
                                <>
                                    {[...Array(10)].map((_, index) => (
                                        <div key={index} style={{marginTop: '16px'}}>
                                            <Skeleton variant="rounded" height={220}/>
                                        </div>
                                    ))}
                                </>
                            ) : !visibleRowsSubusers ? (
                                <div style={{paddingBottom: '200px'}}>
                                    {('components.noData')}
                                </div>
                            ) : (
                                <>
                                    {visibleRowsSubusers?.map((row, index) => (
                                        <SubuserCard item={row} key={index} setDeleteSubuserOpen={setDeleteSubuserOpen} setEditSubuserOpen={setEditSubuserOpen} />
                                    ))}
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        marginBottom: '15px',
                                        padding: '0 30px',
                                    }}>
                                        <Pagination
                                          page={currentPageSubuser}
                                          offset={startIndexSubuser}
                                          limit={subuserLimit}
                                          onPageChange={(newPage) => {
                                              setCurrentPageSubuser(newPage)
                                          }}
                                          total={visibleRowsSubusers?.length || 0}
                                        />
                                        <Button w="max-content" variant="primary"
                                                onClick={() => setCreateSubuserOpen(true)}>
                                            {t('components.createSubuser')}
                                        </Button>
                                    </div>
                                </>
                            )
                        )}
                    </tbody>
                </StyledTable>
                {windowWidth > 768 && (
                    isLoadingSubuser ? (
                        <Skeleton variant="rectangular" height={550}/>
                    ) : (
                        <Table>
                            <thead>
                                <tr>
                                    <th>
                                        {t('components.id')}
                                    </th>
                                    <th>
                                        {t('components.login')}
                                    </th>
                                    <th>
                                        {t('components.access')}
                                    </th>
                                    <th>
                                        {t('components.status')}
                                    </th>
                                    <th style={{
                                        width: '140px',
                                    }}>
                                        {t('components.delete')}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                            {!visibleRowsSubusers?.length ? (
                                <tr>
                                    <td colSpan={5}>
                                        {t('components.noData')}
                                    </td>
                                </tr>
                            ) : (
                                visibleRowsSubusers?.map((row: { id: number; email: string; enabled: any }) => {
                                    return (
                                        <tr key={row?.id}>
                                            <td>{row?.id}</td>
                                            <td>{row?.email}</td>
                                            <td>
                                                <div>
                                                    <Button onClick={() => {
                                                        setEditSubuserOpen(true)
                                                        dispatch(setSubUserData({
                                                            id: row.id,
                                                            enable: row?.enabled,
                                                        }))
                                                    }}>
                                                        <img 
                                                            src={access}
                                                            style={{
                                                                width: '24px',
                                                                height: '24px',
                                                            }}
                                                            alt=""
                                                        />
                                                    </Button>
                                                </div>
                                            </td>
                                            <td>
                                                <div style={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}>
                                                    <Typography style={{
                                                        color: row?.enabled ? 'green' : 'red',
                                                        fontSize: '18px',
                                                    }}>
                                                        {row?.enabled ? 'ACTIVE' : 'DISABLED'}
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td>
                                            <div style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}>
                                                    <Button onClick={() => {
                                                        dispatch(setSubUserData({
                                                            id: row.id,
                                                        }))
                                                        setDeleteSubuserOpen(true)
                                                    }} variant="secondary">
                                                        {t('components.delete')}
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }))
                            }
                            </tbody>
                            <tfoot>
                            <tr>
                                <td colSpan={4}>
                                    <Pagination
                                        page={currentPageSubuser}
                                        offset={startIndexSubuser}
                                        limit={subuserLimit}
                                        onPageChange={(newPage) => {
                                            setCurrentPageSubuser(newPage)
                                        }}
                                        total={visibleRowsSubusers?.length || 0}
                                    />
                                </td>
                                <td style={{textAlign: 'end'}}>
                                    <Button w="max-content" variant="primary"
                                            onClick={() => setCreateSubuserOpen(true)}>
                                        {t('components.createSubuser')}
                                    </Button>
                                </td>
                            </tr>
                            </tfoot>
                        </Table>
                    )
                )}
                <StyledTable>
                    <thead>
                    <tr>
                        <th>{t('components.sessionsList')}:</th>
                    </tr>
                    </thead>
                    <tbody style={{background: '#f2f2f2'}}>
                    {windowWidth <= 768 && (
                        isLoadingSessions || isLoading ? (
                            <>
                                {[...Array(10)].map((_, index) => (
                                    <div key={index} style={{marginTop: '16px'}}>
                                        <Skeleton variant="rounded" height={220}/>
                                    </div>
                                ))}
                            </>
                        ) : !visibleRows ? (
                            <div style={{paddingBottom: '200px'}}>
                                {('components.noData')}
                            </div>
                        ) : (
                            <>
                                {visibleRows?.map((row, index) => (
                                    <SettingsCard item={row} key={index}/>
                                ))}
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: '15px',
                                    padding: '0 30px',
                                }}>
                                    <Pagination
                                        page={currentPage}
                                        offset={startIndex}
                                        limit={sessionLimit}
                                        onPageChange={(newPage) => {
                                            setCurrentPage(newPage)
                                        }}
                                        total={dataSessions?.sessions?.length || 0}
                                    />
                                    <Button variant="primary" onClick={handleClearSessions}>
                                        {t('components.clearSessions')}
                                    </Button>
                                </div>
                            </>
                        ))}
                    </tbody>
                </StyledTable>
                {windowWidth > 768 && (
                            isLoadingSessions || isLoading ? (
                                <Skeleton variant="rectangular" height={550}/>
                            ) : (
                                <Table>
                                    <thead>
                                    <tr>
                                        <th>{t('components.status')}</th>
                                        <th>{t('components.signTime')}</th>
                                        <th>{t('components.ip')}</th>
                                        <th>{t('components.userAgent')}</th>
                                        <th>{t('components.city')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {!visibleRows ? (
                                        <tr>
                                            <td colSpan={5}>
                                                {t('components.noData')}
                                            </td>
                                        </tr>
                                    ) : (
                                        visibleRows?.map((row, index) => {
                                            const date = new Date(row.sign_time).toLocaleString()
                                            return (
                                                <tr key={index}>
                                                    <td style={{color: row.status === 'EXPIRED' ? 'grey' : 'green'}}>{row.status}</td>
                                                    <td>{date}</td>
                                                    <td>{row.ip}</td>
                                                    <td style={{
                                                        color: 'grey',
                                                        fontSize: '14px',
                                                        maxWidth: '400px',
                                                    }}>{row.user_agent}</td>
                                                    <td>{row.city}</td>
                                                </tr>
                                            )
                                        }))
                                    }
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <td colSpan={4}>
                                            <Pagination
                                                page={currentPage}
                                                offset={startIndex}
                                                limit={sessionLimit}
                                                onPageChange={(newPage) => {
                                                    setCurrentPage(newPage)
                                                }}
                                                total={dataSessions?.sessions?.length || 0}
                                            />
                                        </td>
                                        <td style={{textAlign: 'end'}}>
                                            <Button variant="primary" onClick={handleClearSessions}>
                                                {t('components.clearSessions')}
                                            </Button>
                                        </td>
                                    </tr>
                                    </tfoot>
                                </Table>
                            )
                )}
            </TablesWrapper>
        </Container>
            {editSubuserOpen &&
                <EditSubUserModal editSubuserOpen={editSubuserOpen}
                                  setEditSubuserOpen={setEditSubuserOpen}/>}
            {createSubuserOpen &&
                <CreateSubUserModal open={createSubuserOpen}
                                    setOpen={setCreateSubuserOpen}/>}
            {deleteSubuserOpen && 
                <DeleteSubUserModal open={deleteSubuserOpen}
                                    setOpen={setDeleteSubuserOpen}/>}
        </>
    )
}

export default React.memo(Settings)
